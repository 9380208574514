<script setup>
const nuxtApp = useNuxtApp();
const { getMenu } = useWpMenu();

const { data: footerMenu } = await getMenu("footer", nuxtApp);
const { footer, socials, offices } = await useGlobals(nuxtApp);
const copyrightLabel = computed(
  () => `© ${new Date().getFullYear()} WSAudiology`
);

const sameAsSchema = computed(() => {
  let soc = [];
  if (socials?.youtube) soc.push(socials?.youtube);
  if (socials?.linkedin) soc.push(socials?.linkedin);
  if (socials?.instagram) soc.push(socials?.instagram);
  return soc;
});

const officesSchema = computed(() => {
  return offices?.map((office) => {
    let citySplit = office.city.split(" ");
    let city = "";
    let postal = "";
    if (isNaN(citySplit[0])) {
      city = citySplit[0];
      postal = citySplit[1];
    } else {
      city = citySplit[1];
      postal = citySplit[0];
    }
    return {
      "@type": "PostalAddress",
      name: office.title,
      addressCountry: office.country,
      addressLocality: city,
      postalCode: postal,
      streetAddress: office.street,
    };
  });
});

useSchemaOrg([
  defineOrganization({
    name: "WS Audiology", // required by SchemaOrg
    logo: "/wsa-logo.png", // required by SchemaOrg
    address: officesSchema.value,
    sameAs: sameAsSchema,
  }),
]);
</script>

<template>
  <footer class="page-margin footer">
    <div class="footer__inner">
      <div v-if="!$viewport.isLessThan('desktopView')" class="footer__logo">
        <SvgLogo />
      </div>

      <div class="footer__grid">
        <div class="footer__group footer__group--slogan">
          <div class="footer__slogan">
            <SvgWSFA />
          </div>
        </div>

        <div class="footer__group footer__group--contact">
          <address
            class="footer__office ts-caption"
            v-for="office in offices"
            :key="office.street"
          >
            <span class="ts-caption-bold">{{ office.country }}</span>
            <span>{{ office.title }}</span>
            <span>{{ office.street }}</span>
            <span>{{ office.city }}</span>
            <CustomLink
              v-if="office?.phone"
              class="phone"
              :to="`tel:` + office?.phone"
              :target="'blank'"
              aria-label="Office phone number"
              :prefetch="true"
              :ariaLabel="'Office phone number'"
            >
              <template #label>
                <span
                  class="phone"
                  :href="`tel:${office?.phone}`"
                  aria-label="Office phone number"
                >
                  {{ office?.phone }}
                </span></template
              >
            </CustomLink>
          </address>

          <ul class="footer__social">
            <li v-if="socials?.instagram">
              <a
                :href="socials?.instagram"
                target="_blank"
                aria-label="WSA Instagram"
              >
                <SvgInstagram />
              </a>
            </li>
            <li v-if="socials?.linkedin">
              <a
                :href="socials?.linkedin"
                target="_blank"
                aria-label="WSA LinkedIn"
              >
                <SvgLinkedIn />
              </a>
            </li>
            <li v-if="socials?.youtube">
              <a
                :href="socials?.youtube"
                target="_blank"
                aria-label="WSA Youtube"
              >
                <SvgYoutube />
              </a>
            </li>
          </ul>
        </div>

        <div
          class="footer__group footer__group--inline footer__group--copyright"
        >
          <span class="ts-caption">{{ copyrightLabel }}</span>
        </div>

        <div
          class="footer__group footer__group--inline footer__group--navigation"
        >
          <nav class="footer__navigation">
            <ul>
              <li v-for="item in footerMenu[0]" :key="item.order">
                <a
                  class="ts-caption link"
                  :href="item.url"
                  :aria-label="item.title"
                >
                  {{ item.title }}
                </a>
              </li>
              <li>
                <div id="teconsent" class="ts-caption"></div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <div class="footer__background">
      <Media :media="footer?.media" :cover="true" :loop="true" />
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
.footer {
  --line-scale: 0;
  --line-origin: bottom right;
  position: relative;
  color: var(--white);

  .link {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(var(--line-scale));
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform-origin: var(--line-origin);
      transition: transform 0.3s ease-out;
    }

    &:hover {
      --line-scale: 1;
      --line-origin: bottom left;
    }
  }

  &__inner {
    padding: units(6) 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: units(2);

    @media (--md) {
      grid-template-columns: repeat(12, 1fr);
    }
  }

  &__group {
    grid-column: span 2;

    &--inline {
      grid-column: span 1;
    }

    @media (--md) {
      grid-column: span 6;
    }

    &--contact {
      text-decoration: none;

      .phone {
        text-decoration: none;
        display: inline-flex;
        margin-top: units(2);
      }
    }

    @media (--md) {
      &--slogan {
        order: 2;
      }

      &--contact {
        order: 1;
        text-decoration: none;

        .phone {
          text-decoration: none;
        }
      }

      &--copyright {
        order: 3;
      }

      &--navigation {
        order: 4;
      }
    }

    &--contact {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: units(2);
      padding-bottom: 30px;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background: var(--white);
        margin-bottom: units(3);
        bottom: 0;
      }

      @media (--md) {
        grid-template-columns: repeat(6, 1fr);
        padding-bottom: unset;

        &:after {
          display: none;
        }
      }
    }
  }

  &__logo {
    margin-bottom: units(6);
  }

  &__slogan {
    width: 70%;
    margin-bottom: units(6);
    max-width: 350px;

    @media (--md) {
      width: 35%;
    }
  }

  &__office {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    font-style: normal;

    span:first-of-type {
      margin-bottom: units(2);
    }

    /* span:last-of-type {
      margin-top: units(2);
    } */

    @media (--md) {
      grid-column: span 2;
    }
  }

  &__social {
    display: flex;
    grid-column: span 2;
    gap: units(2);
    margin: units(2) 0 units(7) 0;

    @media (--md) {
      grid-column: span 6;
      margin: units(6) 0;
    }

    svg {
      fill: var(--white);
    }
  }

  &__navigation {
    ul {
      @media (--md) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    a {
      color: currentColor;
      text-decoration: none;
      margin-right: units(1);
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;
    line-height: 0;
  }
}
</style>
