<script setup>
const { getMenu } = useWpMenu();
const nuxtApp = useNuxtApp();
const route = useRoute();

const { data: mainMenuData } = await getMenu("primary", nuxtApp);
const { data: secondaryMenuData } = await getMenu("secondary", nuxtApp);

const correctedSecondaryMenuData = computed(() => {
  return secondaryMenuData.value[0].map((secondLvl) => {
    secondLvl.children = secondLvl.children?.filter(
      (item) => item.object_status === "publish"
    );
    return secondLvl;
  });
});

const [menuOpen, toggleMenuOpen] = useToggle(false);
const page = ref(null);
watch(menuOpen, () => {
  page.value = document.querySelector(".page");
  if (menuOpen.value) {
    scrollLock(true, page.value);
  } else {
    scrollLock(false, page.value);
  }
});

const openOverlay = () => {
  useEvent("overlay:open", {
    data: {},
    type: "newsletter",
  });
};
const goHome = () => {
  if (menuOpen.value) {
    setTimeout(() => {
      toggleMenuOpen();
    }, 1000);
  }
};

provide("is-header", true);
</script>

<template>
  <header class="page-margin header" :class="{ 'mobile-menu-open': menuOpen }">
    <WSALink to="/" class="main-logo" aria-label="WSA logo" @click="goHome()">
      <SvgLogo />
    </WSALink>

    <button
      v-if="menuOpen === false"
      @click="toggleMenuOpen()"
      class="mobile-menu-trigger ts-cta"
      aria-label="Open menu"
    >
      <SvgBurger />
    </button>

    <button
      v-else
      @click="toggleMenuOpen()"
      class="mobile-menu-trigger ts-cta"
      aria-label="Close menu"
    >
      <SvgClose />
    </button>

    <div
      class="inner"
      :class="{ 'is-open': $viewport.isLessThan('desktopView') && menuOpen }"
    >
      <div class="navigations">
        <nav class="main-navigation" v-if="mainMenuData[0]">
          <ul>
            <li v-for="item in mainMenuData[0]" :key="item?.id">
              <WSALink
                class="ts-nav-primary link"
                :to="item.url"
                @click="
                  $viewport.isLessThan('desktopView')
                    ? (menuOpen = false)
                    : false
                "
                :aria-label="item?.title"
              >
                {{ item?.title }}
              </WSALink>
            </li>
          </ul>
        </nav>

        <nav
          class="secondary-navigation"
          v-if="correctedSecondaryMenuData?.length"
        >
          <ul role="tablist">
            <li
              v-for="item in correctedSecondaryMenuData"
              :key="item.id"
              role="tab"
            >
              <ElementsAccordionTabs
                v-if="
                  item?.children.length && $viewport.isLessThan('desktopView')
                "
              >
                <ElementsAccordionTab>
                  <template #header>
                    <span class="ts-nav-secondary">{{ item.title }}</span>
                  </template>

                  <template #content>
                    <ul>
                      <li>
                        <WSALink
                          class="ts-nav-sub link overview"
                          :class="{
                            'overview-active':
                              route.path.toLowerCase() === '/investors/' ||
                              route.path.toLowerCase() === '/media/' ||
                              route.path.toLowerCase() === '/about/',
                          }"
                          :to="item.url"
                          @click="
                            $viewport.isLessThan('desktopView')
                              ? (menuOpen = false)
                              : false
                          "
                          :aria-label="item?.title"
                        >
                          Overview
                        </WSALink>
                      </li>
                      <li v-for="item in item.children" :key="item.id">
                        <WSALink
                          class="ts-nav-sub link"
                          :to="item.url"
                          @click="
                            $viewport.isLessThan('desktopView')
                              ? (menuOpen = false)
                              : false
                          "
                          :aria-label="item?.title"
                        >
                          {{ item.title }}
                        </WSALink>
                      </li>
                    </ul>
                  </template>
                </ElementsAccordionTab>
              </ElementsAccordionTabs>

              <WSALink
                v-else
                class="ts-nav-secondary link"
                :class="{
                  'router-link-active router-link-exact-active':
                    item.title.toLowerCase() === 'media' &&
                    (route.name.toLowerCase() === 'press-release-slug' ||
                      route.name.toLowerCase() === 'article-slug'),
                }"
                :to="item.url"
                :aria-label="item?.title"
              >
                {{ item.title }}
              </WSALink>
            </li>
          </ul>
        </nav>
      </div>

      <button
        v-if="constants.ENABLE_NEWSLETTER"
        class="newsletter-trigger ts-caps-bold"
        @click="openOverlay"
        aria-label="Open Newsletter overlay"
      >
        Stay updated
        <SvgArrowSimple />
      </button>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.header {
  --line-scale: 0;
  --mobile-menu-opacity: 0;
  --gradient-visibility: hidden;
  --line-origin: bottom right;
  --mobile-transition: opacity 0.4s ease-out;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--header-height);
  z-index: 101;

  display: flex;
  justify-content: space-between;

  padding-top: units(2);
  padding-bottom: units(2);

  color: var(--white);

  transition: background 0.2s ease-in-out;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: calc(2 * var(--header-height));
    background: linear-gradient(
      var(--loud-indigo) 50%,
      rgba(255, 255, 255, 0.001)
    );
    opacity: var(--mobile-menu-opacity);
    pointer-events: none;
    transition: var(--mobile-transition);
  }

  @media (--md) {
    &:after {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: calc(2 * var(--header-height));
      background: linear-gradient(black 10%, rgba(255, 255, 255, 0.001));
      opacity: 0.5;
      pointer-events: none;
      transition: opacity 0.2s ease-out;
    }
  }

  &.solid {
    &::before {
      opacity: 0;
    }
  }

  &.mobile-menu-open {
    --mobile-menu-opacity: 1;
    --gradient-visibility: visible;
  }

  .main-logo {
    @media (--sm) {
      svg {
        width: 80%;
      }
    }

    @media (--ml) {
      svg {
        width: unset;
      }
    }
  }

  .link {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(var(--line-scale));
      height: 1px;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      background-color: currentColor;
      transform-origin: var(--line-origin);
      transition: transform 0.3s ease-out;
    }

    &:hover {
      --line-scale: 1;
      --line-origin: bottom left;
    }
  }

  .overview {
    &:before {
      display: none;
    }
  }

  .overview-active {
    &:before {
      display: block;
    }
  }

  .mobile-menu-trigger,
  .newsletter-trigger {
    color: currentColor;
    fill: currentColor;
    background: none;
    border: none;

    @media (--md) {
      display: none;
    }
  }

  .mobile-menu-trigger {
    width: 20px;
    height: 20px;
  }

  .newsletter-trigger {
    margin-top: units(3);

    svg {
      margin-left: units(1);
    }
  }

  .inner {
    position: absolute;
    top: calc(var(--header-height) * -1);
    left: 0;
    right: 0;
    z-index: -1;
    height: 100dvh;
    background-color: var(--loud-indigo);
    overflow: auto;
    padding: 0 var(--page-margin) units(6) var(--page-margin);

    opacity: var(--mobile-menu-opacity);
    pointer-events: none;

    transition: var(--mobile-transition);

    margin-top: units(6);

    @media (--md) {
      position: static;
      height: auto;
      overflow: visible;
      padding: unset;
      margin-top: unset;
      opacity: 1;
      pointer-events: auto;
      background-color: transparent;
    }

    &.is-open {
      --mobile-menu-opacity: 1;
      pointer-events: auto;
    }
  }

  .navigations {
    @media (--md) {
      display: flex;
      align-items: center;
    }
  }

  .main-navigation {
    margin-top: units(12);

    @media (--md) {
      margin-top: unset;
    }
  }

  .secondary-navigation {
    position: relative;
    padding: units(3) 0;

    @media (--md) {
      padding: unset;
      margin-left: units(4);
      padding-left: units(4);
    }

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      opacity: 0.5;
      background: var(--white);

      @media (--md) {
        height: 100%;
        width: 1px;
      }
    }

    &:before {
      top: 0;
      left: 0;
    }

    &:after {
      bottom: 0;

      @media (--md) {
        display: none;
      }
    }
  }

  nav {
    ul {
      @media (--md) {
        display: flex;
      }

      li {
        margin: units(2) 0;

        @media (--md) {
          margin: 0 units(1.7);
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      :deep(a) {
        color: var(--white);
        text-decoration: none;
        position: relative;
        padding: 5px 0;

        &.router-link-active {
          &:before {
            content: "";
            width: 100%;
            height: 1px;
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            left: 0;
            bottom: 0px;
          }
        }
      }

      ul {
        columns: 2;
        margin-top: units(3);

        li {
          margin: unset;
          padding: units(1) 0;
        }
      }
    }

    & + nav {
      margin-top: units(9);

      @media (--md) {
        margin-top: unset;
      }
    }
  }
}
</style>
